import {
  ApiAirportType,
  ApiFlightSearchGeoUnitsRequestFromJSON,
  ApiGeoUnit,
  ApiItemType,
  ApiProductData,
  ApiSearchProductDataByCodeRequest,
  ApiSearchProductDataByCodeRequestFromJSON,
  ApiSearchProductDataRequestFromJSON
} from '@ibe/api';
import {
  DestinationItem,
  DestinationItemCategory,
  PackageParams,
  PackageSearchStore
} from '@ibe/components';
import { ApiService, ConfigModel } from '@ibe/services';
import dayjs from 'dayjs';

export const getParentIdFromGeoUnit = (geoUnits: ApiGeoUnit[], code: string) => {
  return geoUnits
    .flatMap(geoUnit => {
      const { children, ...rest } = geoUnit;
      return { ...rest, children: Object.values(children) };
    })
    .find(geoUnit => geoUnit.children.find(child => child.code === code))?.id;
};

export const findChildrenGeoUnitForParent = (
  list: ApiGeoUnit[],
  parentCode: string
): DestinationItem[] => {
  for (let item of list) {
    if (item.code === parentCode) {
      return [
        ...Object.entries(item.children)
          .sort((a, b) => a[1].name?.localeCompare(b[1].name))
          .map(([, value]) => ({
            id: value.id,
            name: value.name,
            code: value.code,
            typeCode: value.typeCode,
            category: DestinationItemCategory.GEO_UNIT,
            parentId: item.id
          }))
      ];
    }
  }
  return [];
};

export const findDestinationGeoUnits = (
  list: ApiGeoUnit[],
  code: string
): DestinationItem[] | undefined => {
  for (let item of list) {
    if (item.code === code) {
      return [
        ...Object.entries(item.children)
          .sort((a, b) => a[1].name?.localeCompare(b[1].name))
          .map(([, value]) => ({
            id: value.id,
            name: value.name,
            code: value.code,
            typeCode: value.typeCode,
            category: DestinationItemCategory.GEO_UNIT,
            parentId: item.id
          })),
        {
          id: item.id,
          name: item.name,
          code: item.code,
          typeCode: item.typeCode,
          category: DestinationItemCategory.GEO_UNIT,
          parentId: getParentIdFromGeoUnit(list, code)
        }
      ]; // return chd separately
    }
    for (let childCode in item.children) {
      if (childCode === code) {
        const child = item.children[childCode];
        return [
          {
            id: child.id,
            name: child.name,
            code: child.code,
            typeCode: child.typeCode,
            category: DestinationItemCategory.GEO_UNIT,
            parentId: item.id
          }
        ];
      }
    }
  }
  return undefined;
};

export const findDestinationProducts = (
  list: ApiProductData[],
  code: string
): DestinationItem | undefined => {
  const foundProduct = list.find(ls => ls.code === code);
  if (foundProduct) {
    return {
      id: foundProduct.id,
      name: foundProduct.description,
      code: foundProduct.code,
      typeCode: foundProduct.itemType,
      category: DestinationItemCategory.PRODUCT
    } as DestinationItem;
  }

  return undefined;
};

export const enrichSearchParamsCodes = (
  params: Partial<PackageParams>,
  enrichmentData: UrlEnrichmentData
) => {
  const destinationsProducts = enrichmentData.destinations.products;
  const destinationsGeoUnits = enrichmentData.destinations.geoUnits;
  if (
    !!params.destinations &&
    params.destinations.length > 0 &&
    (destinationsGeoUnits.length > 0 || destinationsProducts.length > 0)
  ) {
    const resultDestinations: DestinationItem[] = [];
    const resultMainServiceCodes: string[] = [];
    params.destinations.forEach(destinationItem => {
      const foundDestinationGeoUnit = findDestinationGeoUnits(
        destinationsGeoUnits,
        destinationItem.code
      );
      const foundDestinationProduct = findDestinationProducts(
        destinationsProducts,
        destinationItem.code
      );
      if (foundDestinationGeoUnit) {
        resultDestinations.push(
          ...foundDestinationGeoUnit.filter(
            fd => !resultDestinations.find(rd => fd.code === rd.code)
          )
        );
      }
      if (foundDestinationProduct) {
        resultMainServiceCodes.push(foundDestinationProduct.code);
        resultDestinations.push(foundDestinationProduct);
      }
    });

    params.destinations = resultDestinations;
    params.mainServiceCodes = resultMainServiceCodes;
  }

  const { origins } = enrichmentData;
  if (!!params.origins && params.origins.length > 0 && origins.length > 0) {
    const resultOrigins: ApiGeoUnit[] = [];
    params.origins.forEach(origin => {
      const foundOrigin = origins.find(o => o.code === origin.code);
      if (foundOrigin) {
        resultOrigins.push(foundOrigin);
      }
    });
    params.origins = [...new Set(resultOrigins)];
  }
  return params;
};

export const fetchDestinationsGeoUnits = async (api: ApiService): Promise<ApiGeoUnit[]> => {
  const destinationsRQ = ApiSearchProductDataRequestFromJSON({
    type: 'SearchProductDataRequest',
    search: '',
    offset: 0,
    limit: 100,
    itemType: ApiItemType.HOTEL
  });
  const destinationsRS = await api.searchProductData(destinationsRQ);
  return destinationsRS.geoUnits;
};

export const fetchDestinationsProducts = async (
  api: ApiService,
  codes: DestinationItem[]
): Promise<ApiProductData[]> => {
  let products: ApiProductData[] = [];
  const productCodes = codes.filter(
    code => !code.category || code?.category === DestinationItemCategory.PRODUCT
  );
  if (productCodes.length) {
    const destinationsRQ = ApiSearchProductDataByCodeRequestFromJSON({
      codes: productCodes.flatMap(code => code.code).filter(code => code)
    } as ApiSearchProductDataByCodeRequest);
    const destinationsRS = await api.searchProductDataByCode(destinationsRQ);
    products = destinationsRS.products;
  }

  return products;
};

export const fetchOriginsGeoUnits = async (api: ApiService): Promise<ApiGeoUnit[]> => {
  const originsRQ = ApiFlightSearchGeoUnitsRequestFromJSON({
    type: 'FlightSearchGeoUnitsRequest',
    search: '',
    offset: 0,
    limit: 200,
    itemType: ApiItemType.TRANSPORT,
    airportType: ApiAirportType.ORIGIN
  });
  const originsRS = await api.searchGeoUnits(originsRQ);
  return originsRS.geoUnits;
};

export const originsChanged = (oldGeoUnits: ApiGeoUnit[], newGeoUnitCodes: string[]) => {
  const oldCodes = oldGeoUnits.map(gu => gu.code);
  return new Set(oldCodes) === new Set(newGeoUnitCodes);
};

export interface UrlEnrichmentData {
  destinations: {
    geoUnits: ApiGeoUnit[];
    products: ApiProductData[];
  };
  origins: ApiGeoUnit[];
}

function isWithinOneMonth(config: ConfigModel, startDate?: string, endDate?: string) {
  if (!startDate || !endDate) return false;
  const diffInMonths = dayjs(dayjs(endDate).format(config.formatDate)).diff(
    dayjs(dayjs(startDate).format(config.formatDate)),
    'month',
    true
  );
  if (diffInMonths < 0) return false;
  return diffInMonths <= 1;
}

export class CustomPackageSearchStore extends PackageSearchStore {
  validateParamsAndShowError(): void {
    const errorsKeys: string[] = [];
    if (
      // @ts-ignore: enableMultipleRooms exist on YSN
      this.config?.enableMultipleRooms &&
      this.searchParams.occupancy &&
      this.searchParams.occupancy?.length > 1
    ) {
      // if (!this.areDestinationsInTheSameCountry) {
      //   errorsKeys.push('pleaseOnlyOneDestination');
      // }

      if (!isWithinOneMonth(this.config, this.searchParams.startDate, this.searchParams.endDate)) {
        errorsKeys.push('dateShouldBeLessThanOneMonth');
      }
    }
    this.setValidationErrorMessages(errorsKeys);
  }
}
