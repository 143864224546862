import {
  ApiAirportType,
  ApiFlightSearchGeoUnitsRequestFromJSON,
  ApiItemType,
  ApiListOptions,
  ApiSearchGeoUnitsResponse,
  ApiSortDirection,
  ApiSortOption
} from '@ibe/api';
import { PackageSearchParams } from '@ibe/components';
import { parseInt } from 'lodash-es';
import { IbeProps } from '@/components/ibe/IbeProps';
import { AppService, LoggerFactory, ApiService, SessionStorage } from '@ibe/services';
import dayjs from 'dayjs';
import { findDestinationGeoUnits } from '@/components/ibe/IbeUtils';
import YsnConfigModel from '@/Config/YsnConfigModel';

const logger = LoggerFactory.get('globals.ts');
export const VALID_PROMOTION_CODE_DATA = 'ysn-valid-promotion-code-data';

const isClient = (): boolean => {
  return typeof window !== 'undefined';
};

export const defaultListOptions: ApiListOptions = {
  pagination: {
    startingFrom: 0,
    showing: 30,
    currentPage: 1,
    ofTotal: 0
  },
  sorting: {
    sortDirection: ApiSortDirection.ASC,
    sortOption: ApiSortOption.PRICE
  },
  filter: [],
  filteredCount: 0,
  count: 0
};

export const getExternalSearchParams = async (
  props: IbeProps,
  config: YsnConfigModel,
  appService: AppService,
  api: ApiService
): Promise<Partial<PackageSearchParams>> => {
  const { destination, startDate, endDate, numberOfAdults, numberOfChildren } = props;
  const dateFormat = config?.formatDate;
  const geoUnitPromises = [];
  let results: ApiSearchGeoUnitsResponse[] = [];
  let newParams: Partial<PackageSearchParams> = {};
  if (!!destination) {
    const request = ApiFlightSearchGeoUnitsRequestFromJSON({
      type: 'FlightSearchGeoUnitsRequest',
      search: '',
      offset: 1,
      limit: 100,
      itemType: ApiItemType.TRANSPORT,
      airportType: ApiAirportType.DESTINATION
    });
    geoUnitPromises.push(
      appService.config.useIndexedGeoUnits
        ? api.searchGeoUnitsElastic(request)
        : api.searchGeoUnits(request)
    );
  }
  if (geoUnitPromises.length > 0) {
    try {
      results = await Promise.all(geoUnitPromises);
      if (!!origin && !!results[0]) {
        const foundUnit = results[0].geoUnits?.find(unit => unit.code === origin);
        if (!!foundUnit) {
          newParams = { ...newParams, origin: foundUnit };
        }
      }
      if (!!destination && !!results[1]) {
        const foundUnit = findDestinationGeoUnits(results[1].geoUnits, destination);
        if (!!foundUnit) {
          newParams = { ...newParams, destinations: foundUnit };
        }
      }
    } catch (err) {
      logger.error(err);
    }
  }

  if (!!startDate) {
    newParams = { ...newParams, startDate: dayjs(startDate).format(dateFormat) };
  }
  if (!!endDate) {
    newParams = { ...newParams, endDate: dayjs(endDate).format(dateFormat) };
  }
  if (!!numberOfAdults || !!numberOfChildren) {
    newParams = {
      ...newParams,
      occupancy: [
        {
          adults: parseInt(numberOfAdults || '1', 10),
          children: parseInt(numberOfChildren || '0', 10),
          infants: 0,
          childrenAges: [],
          infantsAges: []
        }
      ]
    };
  }
  return newParams;
};

export const stepSessionStorage = new SessionStorage<string>('ysn-step-session-storage');

export type ValidPromotionCode = {
  code: string;
  percentageValue?: number;
  absoluteValue?: number;
  preSign?: string;
  sign?: string;
};

export const validPromotionCodeStorage = new SessionStorage<ValidPromotionCode>(
  VALID_PROMOTION_CODE_DATA
);

export const scrollToAnchor = (id: string, window?: Window | null): void => {
  const anchorElement = window?.document?.getElementById(id);
  if (!!anchorElement) {
    const anchorPosition = anchorElement.getBoundingClientRect().top;
    const offset = anchorPosition + (window?.scrollY || 0);
    window?.scrollTo({ behavior: 'smooth', top: offset });
  }
};

export default isClient;
