import React from 'react';
import { Helmet } from 'react-helmet';

interface CSSVars {
  [key: string]: string;
}

const fontWeightMap: { [key: string]: number } = {
  Light: 300,
  Regular: 400,
  Medium: 500,
  SemiBold: 600
};

const printCss = (
  suffix = '',
  convert: (color: string) => string,
  property: string,
  value: string
) => {
  return `--${property}${suffix ? '-' + suffix : ''}: ${convert(value)};`;
};

const printFont = (property: string, value: string) => {
  const [fontName, fontWeight] = property.split('-');

  return `@font-face {
    font-family: '${fontName}';
    src: url('${value}');
    font-weight: ${fontWeightMap[fontWeight]};
  }
  `;
};

const rgbToHsl = (red: string | number, green: string | number, blue: string | number) => {
  const r = Number(red) / 255;
  const g = Number(green) / 255;
  const b = Number(blue) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  h = Math.round(360 * h);
  s = Math.round(100 * s);
  l = Math.round(100 * l);

  return [h, s, l];
};

// from @josh3736 | https://stackoverflow.com/a/3732187
const colorToHsl = (color: string): number[] => {
  if (color.startsWith('#')) {
    if (color.length === 4) {
      const r = parseInt(color.substr(1, 1) + color.substr(1, 1), 16);
      const g = parseInt(color.substr(2, 1) + color.substr(2, 1), 16);
      const b = parseInt(color.substr(3, 1) + color.substr(3, 1), 16);
      return rgbToHsl(r, g, b);
    } else {
      const r = parseInt(color.substr(1, 2), 16);
      const g = parseInt(color.substr(3, 2), 16);
      const b = parseInt(color.substr(5, 2), 16);
      return rgbToHsl(r, g, b);
    }
  } else if (color.startsWith('rgba')) {
    const [r, g, b] = color.slice(5, -1).split(',');
    return rgbToHsl(r, g, b).slice(0, 3);
  } else if (color.startsWith('rgb')) {
    const [r, g, b] = color.slice(4, -1).split(',');
    return rgbToHsl(r, g, b);
  } else {
    // named color values are not yet supported
    console.error(
      'Named color values are not supported in the config. Convert it manually using this chart: https://htmlcolorcodes.com/color-names/'
    );
    return [0, 0, 16]; // defaults to dark gray
  }
};

function contrastColor(hexColor: string): string {
  // Convert hex color to RGB
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // Calculate perceived luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black or white based on luminance
  return luminance > 0.5 ? '#000000' : '#ffffff';
}

function addContrastProperties(colors: { [key: string]: string }): { [key: string]: string } {
  const colorsWithContrast = { ...colors };

  for (const colorName in colors) {
    if (Object.prototype.hasOwnProperty.call(colors, colorName)) {
      const colorValue = colors[colorName];
      const contrastPropertyName = `${colorName}Contrast`;
      colorsWithContrast[contrastPropertyName] = contrastColor(colorValue);
    }
  }

  return colorsWithContrast;
}

function filterObjectProperties(cssVars: CSSVars, includedSubString: string): CSSVars {
  const filteredVars: CSSVars = {};
  for (const key in cssVars) {
    if (Object.prototype.hasOwnProperty.call(cssVars, key)) {
      if (key.toLowerCase().includes(includedSubString.toLowerCase())) {
        filteredVars[key] = cssVars[key];
      }
    }
  }

  return filteredVars;
}

interface ApplyBrandingProps {
  cssVars: CSSVars;
  faviconUrl?: string;
}

const ApplyBranding = (props: ApplyBrandingProps): React.JSX.Element => {
  const { cssVars, faviconUrl } = props;

  const defaultFaviconUrl = './favicon.svg';

  const colors = filterObjectProperties(cssVars, 'Color');
  const fonts = filterObjectProperties(cssVars, 'Font');

  const colorsWithContrast = addContrastProperties(colors);

  if (cssVars) {
    return (
      <Helmet>
        <style>
          {`body {
          ${
            colorsWithContrast &&
            Object.keys(colorsWithContrast)
              .map(key => {
                return printCss(
                  '',
                  color => {
                    const hsl = colorToHsl(color);
                    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
                  },
                  key,
                  colorsWithContrast[key] || ''
                );
              })
              .join('')
          }
          ${
            colorsWithContrast &&
            Object.keys(colorsWithContrast)
              .map(key => {
                return printCss(
                  'h',
                  color => {
                    const hsl = colorToHsl(color);
                    return `${hsl[0]}`;
                  },
                  key,
                  colorsWithContrast[key] || ''
                );
              })
              .join('')
          }         
          ${
            colorsWithContrast &&
            Object.keys(colorsWithContrast)
              .map(key => {
                return printCss(
                  's',
                  color => {
                    const hsl = colorToHsl(color);
                    return `${hsl[1]}%`;
                  },
                  key,
                  colorsWithContrast[key] || ''
                );
              })
              .join('')
          }
          ${
            colorsWithContrast &&
            Object.keys(colorsWithContrast)
              .map(key => {
                return printCss(
                  'l',
                  color => {
                    const hsl = colorToHsl(color);
                    return `${hsl[2]}%`;
                  },
                  key,
                  colorsWithContrast[key] || ''
                );
              })
              .join('')
          }
          }
          ${fonts && Object.keys(fonts).map(key => printFont(key, fonts[key]))}
          `}
        </style>
        <link
          rel="shortcut icon"
          media="all"
          sizes="16x16 32x32 96x96"
          href={faviconUrl || defaultFaviconUrl}
        />
      </Helmet>
    );
  } else return <></>;
};

export default ApplyBranding;
